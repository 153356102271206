import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../app/layout"

import { Row, Col, Breadcrumb, Button } from "antd"
import Laporan from "../../components/Laporan"
import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"
import ScrollAnimation from "react-animate-on-scroll"
import { useI18next } from "gatsby-plugin-react-i18next"

const LapThnPage = ({
  data: {
    allLaporanTahunan: { nodes: data },

    reportSettings: { laporan_tahunan: settings = {} },
  },
}) => {
  const [more, setMore] = useState(0)
  const lists = [...data].slice(0, (more + 1) * 12)
  const { t } = useI18next()
  return (
    <Layout module_name="information" page_name="laporan-tahunan">
      <Seo
        title="Laporan Tahunan"
        module_name="information"
        page_name="laporan-tahunan"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{settings.page_title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">{settings.page_title}</h1>
          </ScrollAnimation>
          <Row gutter={40} style={{ margin: "0 -15px" }}>
            {lists.map(function (item, i) {
              return (
                <Col span={24} md={6} key={i} style={{ padding: "0 15px" }}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <Laporan
                      title={item.title}
                      image={item.thumbnail_image}
                      fileurl={item.file}
                    />
                  </ScrollAnimation>
                </Col>
              )
            })}
          </Row>

          {lists.length !== data.length && data.length > 12 && (
            <div className="text-center" style={{ marginTop: 30 }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Button
                  type="link"
                  onClick={() => setMore(more + 1)}
                  style={{ textTransform: "capitalize", direction: "ltr" }}
                >
                  {t("...Muat Lebih")}
                </Button>
              </ScrollAnimation>
            </div>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export default LapThnPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allLaporanTahunan(filter: { lang: { eq: $language } }) {
      nodes {
        thumbnail_image
        title
        file
      }
    }
    reportSettings(lang: { eq: $language }) {
      laporan_tahunan {
        page_title
        page_name
        slug
      }
    }
  }
`
